import { default as _91slug_93ExkNLfxB9gMeta } from "/codebuild/output/src2307107498/src/synergos-frontend/pages/blog/[slug].vue?macro=true";
import { default as index42JFSaISRkMeta } from "/codebuild/output/src2307107498/src/synergos-frontend/pages/blog/index.vue?macro=true";
import { default as blogHGUOKLGo9FMeta } from "/codebuild/output/src2307107498/src/synergos-frontend/pages/blog.vue?macro=true";
import { default as checkoutSuccessuXM9QwhSePMeta } from "/codebuild/output/src2307107498/src/synergos-frontend/pages/checkoutSuccess.vue?macro=true";
import { default as dashboardAqeQ62ZWeuMeta } from "/codebuild/output/src2307107498/src/synergos-frontend/pages/dashboard.vue?macro=true";
import { default as _91_46_46_46slug_93lVs7Putva3Meta } from "/codebuild/output/src2307107498/src/synergos-frontend/pages/docs/[...slug].vue?macro=true";
import { default as _91sessionId_93qvNhLzZMV6Meta } from "/codebuild/output/src2307107498/src/synergos-frontend/pages/experience/[sessionId].vue?macro=true";
import { default as endAYgk4Z8OUfMeta } from "/codebuild/output/src2307107498/src/synergos-frontend/pages/experience/end.vue?macro=true";
import { default as _91_46_46_46slug_93gg269G2sQ7Meta } from "/codebuild/output/src2307107498/src/synergos-frontend/pages/experience/live/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93HWp10VPtk6Meta } from "/codebuild/output/src2307107498/src/synergos-frontend/pages/experience/wait/[...slug].vue?macro=true";
import { default as indexIfqEOtyghWMeta } from "/codebuild/output/src2307107498/src/synergos-frontend/pages/index.vue?macro=true";
import { default as loginu2bNnyve1HMeta } from "/codebuild/output/src2307107498/src/synergos-frontend/pages/login.vue?macro=true";
import { default as pricingWFCb3zpmJqMeta } from "/codebuild/output/src2307107498/src/synergos-frontend/pages/pricing.vue?macro=true";
import { default as signupFzi6SSM4jeMeta } from "/codebuild/output/src2307107498/src/synergos-frontend/pages/signup.vue?macro=true";
import { default as systemCheckrEQKSO2rsVMeta } from "/codebuild/output/src2307107498/src/synergos-frontend/pages/systemCheck.vue?macro=true";
import { default as component_45stubj5kqfyicW6Meta } from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubj5kqfyicW6 } from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: blogHGUOKLGo9FMeta?.name,
    path: "/blog",
    component: () => import("/codebuild/output/src2307107498/src/synergos-frontend/pages/blog.vue"),
    children: [
  {
    name: "blog-slug",
    path: ":slug()",
    component: () => import("/codebuild/output/src2307107498/src/synergos-frontend/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "",
    component: () => import("/codebuild/output/src2307107498/src/synergos-frontend/pages/blog/index.vue")
  }
]
  },
  {
    name: "checkoutSuccess",
    path: "/checkoutSuccess",
    meta: checkoutSuccessuXM9QwhSePMeta || {},
    component: () => import("/codebuild/output/src2307107498/src/synergos-frontend/pages/checkoutSuccess.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardAqeQ62ZWeuMeta || {},
    component: () => import("/codebuild/output/src2307107498/src/synergos-frontend/pages/dashboard.vue")
  },
  {
    name: "docs-slug",
    path: "/docs/:slug(.*)*",
    component: () => import("/codebuild/output/src2307107498/src/synergos-frontend/pages/docs/[...slug].vue")
  },
  {
    name: "experience-sessionId",
    path: "/experience/:sessionId()",
    meta: _91sessionId_93qvNhLzZMV6Meta || {},
    component: () => import("/codebuild/output/src2307107498/src/synergos-frontend/pages/experience/[sessionId].vue")
  },
  {
    name: "experience-end",
    path: "/experience/end",
    meta: endAYgk4Z8OUfMeta || {},
    component: () => import("/codebuild/output/src2307107498/src/synergos-frontend/pages/experience/end.vue")
  },
  {
    name: "experience-live-slug",
    path: "/experience/live/:slug(.*)*",
    meta: _91_46_46_46slug_93gg269G2sQ7Meta || {},
    component: () => import("/codebuild/output/src2307107498/src/synergos-frontend/pages/experience/live/[...slug].vue")
  },
  {
    name: "experience-wait-slug",
    path: "/experience/wait/:slug(.*)*",
    meta: _91_46_46_46slug_93HWp10VPtk6Meta || {},
    component: () => import("/codebuild/output/src2307107498/src/synergos-frontend/pages/experience/wait/[...slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexIfqEOtyghWMeta || {},
    component: () => import("/codebuild/output/src2307107498/src/synergos-frontend/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginu2bNnyve1HMeta || {},
    component: () => import("/codebuild/output/src2307107498/src/synergos-frontend/pages/login.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingWFCb3zpmJqMeta || {},
    component: () => import("/codebuild/output/src2307107498/src/synergos-frontend/pages/pricing.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupFzi6SSM4jeMeta || {},
    component: () => import("/codebuild/output/src2307107498/src/synergos-frontend/pages/signup.vue")
  },
  {
    name: "systemCheck",
    path: "/systemCheck",
    meta: systemCheckrEQKSO2rsVMeta || {},
    component: () => import("/codebuild/output/src2307107498/src/synergos-frontend/pages/systemCheck.vue")
  },
  {
    name: component_45stubj5kqfyicW6Meta?.name,
    path: "/docs",
    component: component_45stubj5kqfyicW6
  }
]