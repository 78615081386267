import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src2307107498/src/synergos-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eskviOYyLt from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import primevue_plugin_egKpok8Auk from "/codebuild/output/src2307107498/src/synergos-frontend/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_tbFNToZNim from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import preview_client_yA6XwA2wVr from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import slideovers_X7whXyrIWR from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_Eg4DPJPcY6 from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_1aypKNZ222 from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_kQtglGecod from "/codebuild/output/src2307107498/src/synergos-frontend/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import sentry_client_shVUlIjFLk from "/codebuild/output/src2307107498/src/synergos-frontend/plugins/sentry.client.ts";
import ably_C0UXWkkp3n from "/codebuild/output/src2307107498/src/synergos-frontend/plugins/ably.ts";
import loadUser_LsqsWAQztf from "/codebuild/output/src2307107498/src/synergos-frontend/plugins/loadUser.ts";
import vuetify_7h9QAQEssH from "/codebuild/output/src2307107498/src/synergos-frontend/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_eskviOYyLt,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  plugin_tbFNToZNim,
  preview_client_yA6XwA2wVr,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  scrollbars_client_Eg4DPJPcY6,
  presets_1aypKNZ222,
  variables_kQtglGecod,
  sentry_client_shVUlIjFLk,
  ably_C0UXWkkp3n,
  loadUser_LsqsWAQztf,
  vuetify_7h9QAQEssH
]